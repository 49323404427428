<template>
    <div class="hC43Box">
        <div class="hbBox1">
            <img v-if="getAdvData(25).children" :src="getAdvData(25).children[0].image" class="hb1Bg" />
            <div class="hb1Box">
                <div class="hb1T">
                    <p class="ht1">开屏广告</p>
                    <p class="ht2">威有开屏矩阵，是以围绕威有APP搭建的威有系开屏产品矩阵</p>
                </div>
                <div class="hb1Btn">
                    <a :href="getAdvData(25).children[0].url" target="_blank" rel="noopener noreferrer">
                        立即体验
                        <div class="hb1Code">
                            <img :src="getWebData().wechat_visitor" alt="" class="hcImg" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="hbBox2">
            <div class="hb2Box">
                <div class="h2bT">
                    <p class="ht1">广告优势</p>
                    <p class="ht2">基于大数据赋能和意图营销的优质移动营销产品</p>
                </div>
                <div class="h2bList">
                    <ul>
                        <li class="hlLi" v-for="item in list" :key="item.id">
                            <div class="hll1">
                                <img :src="item.image" class="h1Img" />
                            </div>
                            <div class="hll2">
                                <p class="h2p1">{{ item.title }}</p>
                                <p class="h2p2">{{ item.describe }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="h2bBtn">
                    立即获取专属流量
                    <div class="hb1Code">
                        <img :src="getWebData().wechat_visitor" alt="" class="hcImg" />
                    </div>
                </div>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Top">
                <div class="h3tT">
                    <p class="ht1">广告展现形式</p>
                    <p class="ht2">覆盖全场景的威有系开展营销生态体系</p>
                    <p class="ht3">覆盖用户24小时工作生活全时长|覆盖近9成移动互联网主流人群</p>
                </div>
                <div class="hb3Tab">
                    <ul>
                        <li class="hb3Li" @mousemove="showHb3(1)" :class="{ hb3Act: theHb3 === 1 }">
                            <img src="http://iv.vu818.com/img/hc4213.png" alt="" class="hlIcon" />
                        </li>
                        <li class="hb3Li" @mousemove="showHb3(2)" :class="{ hb3Act: theHb3 === 2 }">
                            <img src="http://iv.vu818.com/img/hc42132.png" alt="" class="hlIcon" />
                        </li>
                        <li class="hb3Li" @mousemove="showHb3(3)" :class="{ hb3Act: theHb3 === 3 }">
                            <img src="http://iv.vu818.com/img/hc42133.png" alt="" class="hlIcon" />
                        </li>
                        <li class="hb3Li" @mousemove="showHb3(4)" :class="{ hb3Act: theHb3 === 4 }">
                            <img src="http://iv.vu818.com/img/hc42134.png" alt="" class="hlIcon" />
                        </li>
                        <li class="hb3Li" @mousemove="showHb3(5)" :class="{ hb3Act: theHb3 === 5 }">
                            <img src="http://iv.vu818.com/img/hc42135.png" alt="" class="hlIcon" />
                        </li>
                        <li class="hb3Li" @mousemove="showHb3(6)" :class="{ hb3Act: theHb3 === 6 }">
                            <img src="http://iv.vu818.com/img/hc42136.png" alt="" class="hlIcon" />
                        </li>
                        <li class="hb3Li" @mousemove="showHb3(7)" :class="{ hb3Act: theHb3 === 7 }">
                            <img src="http://iv.vu818.com/img/hc42137.png" alt="" class="hlIcon" />
                        </li>
                        <li class="hb3Li" @mousemove="showHb3(8)" :class="{ hb3Act: theHb3 === 8 }">
                            <img src="http://iv.vu818.com/img/hc42138.png" alt="" class="hlIcon" />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 1">
                <img src="http://iv.vu818.com/img/hc4214.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc4215.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc4216.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc4217.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 2">
                <img src="http://iv.vu818.com/img/hc42142.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc42143.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc42144.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc42145.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 3">
                <img src="http://iv.vu818.com/img/hc42146.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc42147.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc42148.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc42149.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 4">
                <img src="http://iv.vu818.com/img/hc42150.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc42151.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc42152.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc42153.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 5">
                <img src="http://iv.vu818.com/img/hc42154.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc42155.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc42156.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc42157.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 6">
                <img src="http://iv.vu818.com/img/hc42158.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc42159.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc42160.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc42161.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 7">
                <img src="http://iv.vu818.com/img/hc42162.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc42163.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc42164.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc42165.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hb3Content" v-show="showh3C === 8">
                <img src="http://iv.vu818.com/img/hc42166.png" alt="" class="h3cBg" />
                <div class="h3cBox">
                    <div class="h3cImgs">
                        <img src="http://iv.vu818.com/img/hc42167.png" alt="" class="h3cL" />
                        <div class="h3Rt">
                            <img src="http://iv.vu818.com/img/hc42168.png" alt="" class="h3cR" />
                            <img src="http://iv.vu818.com/img/hc42169.png" alt="" class="h3cR2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="h3cBtn">
                开始投放
                <div class="hb1Code">
                    <img :src="getWebData().wechat_visitor" alt="" class="hcImg" />
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <img src="http://iv.vu818.com/img/hc4218.png" alt="" class="hb4Bg" />
            <div class="hb4Box">
                <div class="hb4Top">
                    <p class="ht1">推广案例</p>
                    <p class="ht2">通过威有营销开屏广告，进行高效推广，触达精准目标客户</p>
                </div>
                <div class="h4bBox">
                    <div class="hbLeft">
                        <img src="http://iv.vu818.com/img/hc4219.png" alt="" class="hblImg" />
                    </div>
                    <div class="hbRight">
                        <div class="hrBox">
                            <div class="hrb1">威有 X 威有智能屏:Break <br />Moment休闲场景营销</div>
                            <div class="hrb2">
                                威有联合威有智能屏打造Break Moment休闲场景营销， 通过威有开屏、社交平台造势、威有场景
                                营销等传播方式让 威有#畅爽好喝，让工作更带劲#的形象深度植入目标消费 群体的心中。
                            </div>
                            <div class="hrb3">
                                <ul>
                                    <li class="h3Li">
                                        <p class="h3l1"><span>6266</span>万</p>
                                        <p class="h3l2">总曝光量</p>
                                    </li>
                                    <li class="h3Li">
                                        <p class="h3l1"><span>191</span>万</p>
                                        <p class="h3l2">总互动量</p>
                                    </li>
                                    <li class="h3Li">
                                        <p class="h3l1"><span>85</span>%</p>
                                        <p class="h3l2">天气口播完播率</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="hrb4">
                                <div class="h4Btn">
                                    免费咨询
                                    <div class="hb1Code">
                                        <img :src="getWebData().wechat_visitor" alt="" class="hcImg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "HsContent43",
    inject: ["getWebData", "getAdvData"],
    data() {
        return {
            theHb3: 1,
            showh3C: 1,
            list: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        showHb3(i) {
            this.theHb3 = i;
            this.showh3C = i;
        },
        async getData() {
            let { data } = await axios.get("/api/marketing/adlist");
            this.list = data;
        },
    },
};
</script>

<style lang="less" scoped>
.hC43Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        width: 100%;
        // height: 450px;
        // overflow: hidden;
        position: relative;
        text-align: center;
        .hb1Bg {
            width: 100%;
            max-width: 1440px;
            z-index: -1;
            margin: 0 auto;
        }
        .hb1Box {
            width: 1100px;
            height: 129px;
            position: absolute;
            top: 150px;
            left: 50%;
            transform: translateX(-50%);
            text-align: left;
            .hb1T {
                width: 100%;
                height: 84px;
                .ht1 {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    font-size: 40px;
                    color: #333333;
                    font-weight: 500;
                }
                .ht2 {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    color: #333333;
                }
            }
            .hb1Btn {
                width: 200px;
                height: 35px;
                line-height: 35px;
                font-size: 14px;
                text-align: center;
                color: white;
                border-radius: 5px;
                background-color: rgb(119 129 241);
                opacity: 0.8;
                margin-top: 7px;
                cursor: pointer;
                position: relative;
                a {
                    color: white;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .hb1Code {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                    overflow: hidden;
                    position: absolute;
                    top: -110px;
                    left: 50px;
                    opacity: 0;
                    transition: 0.5s;
                    .hcImg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .hb1Btn:hover {
                opacity: 1;
                .hb1Code {
                    opacity: 1;
                }
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 525px;
        margin: 0 auto;
        margin-top: 100px;
        .hb2Box {
            max-width: 1200px;
            height: 100%;
            margin: 0 auto;
            .h2bT {
                width: 100%;
                height: 82px;
                text-align: center;
                .ht1 {
                    width: 100%;
                    height: 51px;
                    line-height: 51px;
                    font-size: 34px;
                }
                .ht2 {
                    width: 100%;
                    height: 21px;
                    line-height: 21px;
                    margin-top: 5px;
                    color: #7f7f7f;
                    font-size: 14px;
                }
            }
            .h2bList {
                width: 100%;
                height: 327px;
                margin-top: 20px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hlLi {
                        width: 320px;
                        height: 100%;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        .hll1 {
                            width: 100%;
                            height: 200px;
                            overflow: hidden;
                            cursor: pointer;
                            .h1Img {
                                width: 100%;
                                height: 100%;
                                transition: 0.5s;
                            }
                        }
                        .hll2 {
                            width: 100%;
                            height: 125px;
                            .h2p1 {
                                width: 258px;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                font-size: 20px;
                                margin: 0 auto;
                                margin-top: 10px;
                            }
                            .h2p2 {
                                width: 258px;
                                height: 50px;
                                line-height: 25px;
                                font-size: 14px;
                                text-align: center;
                                margin: 0 auto;
                                margin-top: 10px;
                                word-wrap: break-word;
                            }
                        }
                    }
                    .hlLi:hover {
                        .h1Img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            .h2bBtn {
                width: 200px;
                height: 35px;
                line-height: 35px;
                font-size: 14px;
                text-align: center;
                color: white;
                border-radius: 5px;
                background-color: rgb(119 129 241);
                opacity: 0.8;

                margin: 0 auto;
                margin-top: 40px;
                cursor: pointer;
                position: relative;
                .hb1Code {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                    overflow: hidden;
                    position: absolute;
                    top: -110px;
                    left: 50px;
                    opacity: 0;
                    transition: 0.5s;
                    .hcImg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .h2bBtn:hover {
                opacity: 1;
                .hb1Code {
                    opacity: 1;
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 1018px;
        overflow: hidden;
        background-color: #f5f8ff;
        position: relative;
        .hb3Top {
            width: 1200px;
            height: 203px;
            margin: 0 auto;
            margin-top: 100px;
            .h3tT {
                width: 100%;
                height: 103px;
                text-align: center;
                .ht1 {
                    width: 100%;
                    height: 51px;
                    line-height: 51px;
                    font-size: 34px;
                    font-weight: 500;
                }
                .ht2,
                .ht3 {
                    width: 100%;
                    height: 21px;
                    line-height: 21px;
                    font-size: 14px;
                }
                .ht3 {
                    margin-top: 5px;
                    color: #7f7f7f;
                }
            }
            .hb3Tab {
                width: 100%;
                height: 60px;
                margin-top: 25px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hb3Li {
                        width: 50px;
                        height: 50px;
                        border-radius: 5px;
                        position: relative;
                        transition: 0.5s;
                        cursor: pointer;
                        .hlIcon {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .hb3Li::before {
                        content: "";
                        display: none;
                        position: absolute;
                        bottom: -20px;
                        left: calc(50% - 10px);
                        width: 20px;
                        height: 2px;
                        border-radius: 1px;
                        background-color: rgb(119 129 241);
                    }
                    .hb3Act {
                        width: 60px;
                        height: 60px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                    }
                    .hb3Li.hb3Act::before {
                        display: block;
                    }
                }
            }
        }
        .hb3Content {
            width: 100%;
            height: 620px;
            margin-top: 40px;
            position: relative;
            .h3cBg {
                width: 100%;
                max-width: 1440px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                object-fit: cover;
            }
            .h3cBox {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                position: relative;
                overflow: hidden;
                .h3cImgs {
                    width: 100%;
                    height: 550px;
                    overflow: hidden;
                    transition: 0.5s;
                    .h3cL {
                        width: 428px;
                        height: 290px;
                        margin-top: 140px;
                        margin-left: 140px;
                        float: left;
                        animation-name: img-l;
                        animation-duration: 1s;
                        animation-timing-function: ease-in-out;
                    }
                    .h3Rt {
                        width: 363px;
                        height: 456px;
                        float: left;
                        margin-left: 30px;
                        animation-name: img-rt;
                        animation-duration: 1s;
                        animation-timing-function: ease-in-out;
                        .h3cR {
                            width: 170px;
                            height: 356px;
                            margin-top: 140px;
                            float: left;
                            animation-name: img-r;
                            animation-duration: 1s;
                            animation-delay: 0.5s;
                            animation-timing-function: ease-in-out;
                        }
                        .h3cR2 {
                            width: 170px;
                            height: 356px;
                            margin-top: 60px;
                            margin-left: 15px;
                            float: left;
                            animation-name: img-r;
                            animation-duration: 1s;
                            animation-delay: 1s;
                            animation-timing-function: ease-in-out;
                        }
                    }
                }
                @keyframes img-l {
                    0% {
                        transform: translateX(-400px);
                    }
                    100% {
                        transform: translateX(0px);
                    }
                }
                @keyframes img-r {
                    0% {
                        opacity: 0;
                        transform: scale3d(0.3, 0.3, 0.3);
                    }
                    20% {
                        transform: scale3d(1.1, 1.1, 1.1);
                    }
                    40% {
                        transform: scale3d(0.9, 0.9, 0.9);
                    }
                    60% {
                        opacity: 1;
                        transform: scale3d(1.03, 1.03, 1.03);
                    }
                    80% {
                        transform: scale3d(0.97, 0.97, 0.97);
                    }
                    100% {
                        opacity: 1;
                        transform: scale3d(1, 1, 1);
                    }
                }
                @keyframes img-rt {
                    0% {
                        transform: translateX(400px);
                    }
                    100% {
                        transform: translateX(0px);
                    }
                }
            }
        }
        .h3cBtn {
            width: 150px;
            height: 35px;
            line-height: 35px;
            font-size: 14px;
            text-align: center;
            color: white;
            border-radius: 5px;
            background-color: rgb(119 129 241);
            opacity: 0.8;

            margin: 0 auto;
            margin-top: 7px;
            cursor: pointer;
            position: absolute;
            bottom: 85px;
            left: calc(50% - 50px);
            .hb1Code {
                width: 100px;
                height: 100px;
                border-radius: 10px;
                overflow: hidden;
                position: absolute;
                top: -110px;
                left: 50px;
                opacity: 0;
                transition: 0.5s;
                .hcImg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .h3cBtn:hover {
            opacity: 1;
            .hb1Code {
                opacity: 1;
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 732px;
        position: relative;
        .hb4Bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            object-fit: cover;
        }
        .hb4Box {
            width: 1200px;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
            .hb4Top {
                width: 100%;
                height: 82px;
                text-align: center;
                margin-top: 50px;
                .ht1 {
                    width: 100%;
                    height: 51px;
                    line-height: 51px;
                    font-size: 34px;
                    font-weight: bold;
                }
                .ht2 {
                    width: 100%;
                    height: 21px;
                    line-height: 21px;
                    color: #7f7f7f;
                    font-size: 14px;
                    margin-top: 5px;
                }
            }
            .h4bBox {
                width: 100%;
                height: 440px;
                margin-top: 20px;
                .hbLeft {
                    width: 530px;
                    height: 100%;
                    float: left;
                    .hblImg {
                        width: 100%;
                        height: 100%;
                    }
                }
                .hbRight {
                    width: 640px;
                    height: 440px;
                    overflow: hidden;
                    float: left;
                    background-color: rgba(0, 0, 0, 0.5);
                    .hrBox {
                        width: 90%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 7%;
                        color: white;
                        .hrb1 {
                            width: 100%;
                            height: 95px;
                            line-height: 45px;
                            padding-bottom: 10px;
                            margin-bottom: 10px;
                            border-bottom: 1px dashed #ddd;
                            font-size: 28px;
                            font-weight: bold;
                        }
                        .hrb2 {
                            width: 100%;
                            height: 63px;
                            line-height: 20px;
                            font-size: 14px;
                        }
                        .hrb3 {
                            width: 100%;
                            height: 88px;
                            margin-top: 20px;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: space-around;
                                .h3Li {
                                    width: 173px;
                                    height: 100%;
                                    text-align: center;
                                    font-size: 14px;
                                    .h3l1 {
                                        width: 100%;
                                        height: 67px;
                                        line-height: 67px;
                                        span {
                                            font-weight: bold;
                                            font-size: 45px;
                                        }
                                    }
                                    .h3l2 {
                                        width: 100%;
                                        height: 21px;
                                        line-height: 21px;
                                    }
                                }
                            }
                        }
                        .hrb4 {
                            width: 100%;
                            height: 35px;
                            margin-top: 25px;
                            .h4Btn {
                                width: 150px;
                                height: 35px;
                                line-height: 35px;
                                font-size: 14px;
                                text-align: center;
                                color: white;
                                border-radius: 5px;
                                background-color: rgb(119 129 241);
                                opacity: 0.8;

                                cursor: pointer;
                                position: relative;
                                .hb1Code {
                                    width: 100px;
                                    height: 100px;
                                    border-radius: 10px;
                                    overflow: hidden;
                                    position: absolute;
                                    top: -110px;
                                    left: 50px;
                                    opacity: 0;
                                    transition: 0.5s;
                                    .hcImg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                            .h4Btn:hover {
                                opacity: 1;
                                .hb1Code {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
